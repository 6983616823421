<template>
  <div>
    <editable-card
      title="Personal Details"
      :read-view="userComponents.read"
      :edit-view="userComponents.edit"
      :child-props="userProps"
      :isAdmin="true"
      :show-edit="false"
      class="mb-4"
    />
    <b-button
      variant="outline-primary"
      :href="url"
    >
      Reset Password
    </b-button>
  </div>
</template>
<script>
import {mapActions, mapState} from "vuex";
import EditableCard from "../interface/EditableCard";
import UserView from "./UserView";
import UserEdit from "./UserEdit";

export default {
  name: "UserDetails",
  components: {
    EditableCard,
  },
  computed: {
    ...mapState("auth", {user: state => state.currentUser}),
    ...mapState("configStore", {sfApplicantUrl: state => state.sfApplicantUrl}),
    userProps() {
      return {
        user: this.user,
      };
    },
    url() {
      return this.sfApplicantUrl + "/applicant/edit";
    }
  },
  data() {
    return {
      userComponents: {
        read: UserView,
        edit: UserEdit,
      },
    };
  },
  methods: {
    ...mapActions("configStore", ["fetchSfApplicantUrl"]),
  },
  mounted() {
    this.fetchSfApplicantUrl();
  }
};
</script>

<style scoped>
.display_page_title {
  display: inline-block;
}
</style>
